/**
 * Convierte una cadena base64 a una case blob de tipo pdf (application/pdf)
 * @param base64 cadena en formato base64
 * @returns Un Blob
 */
const base64ToBlob = (base64: string): Blob=> {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
};

  export {base64ToBlob}